/** @jsx jsx */
import { graphql } from "gatsby"
import { useState } from "react"
import { jsx } from "theme-ui"
import Layout from "~/components/layout"
import Seo from "~/components/seo"
import DotsIcon from "~/images/dots.inline.svg"
import Values from "~/components/about/values"
import Articles from "~/components/articles"

const WhoWeArePage = ({ data: { page }}) => {
  const [visionMission, setVisionMission] = useState('vision')
  return (
    <Layout>
      <Seo title={page.title} />
      <section
        sx={{
          backgroundImage: `url('${page.featuredImage?.node?.localFile?.publicURL}')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderBottomRightRadius: '50% 10%'
        }}
        className="relative pt-44 bg-primary overflow-hidden">
        <div className="container mx-auto px-8">
          <div className="flex flex-wrap -mx-8">
            <div className="flex w-full px-8">
              <h1
                className="text-5xl md:text-7xl text-white mb-8 md:mb-20">{page.title}
              </h1>
            </div>
          </div>
          <div className="flex flex-wrap -mx-8">
            <div className="flex flex-col justify-end w-full md:w-1/3 px-8">
              <small className="text-secondary mb-10">When it Matters</small>
            </div>
            <div className="flex items-end w-full md:w-2/3 px-8">
              <div
                className="relative w-full bg-secondary bg-opacity-80 pt-8 pb-6 px-12">
                <DotsIcon
                  className="absolute top-0 left-0 -mt-8 -ml-8 w-32 opacity-40" />
                <div
                  className="text-white"
                  dangerouslySetInnerHTML={{
                    __html: page.content
                  }} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-24 pb-12">
        <div className="container mx-auto px-8">
          <div className="flex flex-wrap justify-center -mx-8">
            <div className="w-full text-center px-8">
              <h6
                className="text-secondary mb-6">{ page.title }</h6>
              <h1
                className="text-6xl text-primary">
                  {page?.whoWeAre?.headline}
              </h1> 
            </div>
          </div>
        </div>
      </section>
      <section className="pb-24">
        <div className="container mx-auto px-8">
          <div className="flex justify-center">
            <div
              className="w-full md:w-3/4 flex flex-wrap -mx-8">

              <div className={`flex flex-col w-full md:w-1/2 px-8 items-end text-right ${visionMission === 'vision' ? 'opacity-100' : 'opacity-30'}`}>
                <h3
                  role="presentation"
                  onClick={() => {
                    setVisionMission('vision')
                  }}
                  className="text-3xl text-primary cursor-pointer mb-4">{ page.whoWeAre?.vision?.tabTitle }</h3>
                  <div
                    className={`w-full md:w-1/2 ${visionMission === 'vision' ? 'bg-secondary h-1.5' : 'bg-gray-400 h-0.5 mb-1'}`} />
                  <p className="mt-4">{ page.whoWeAre?.vision?.content }</p>
              </div>
              <div className={`flex flex-col w-full md:w-1/2 px-8 ${visionMission === 'mission' ? 'opacity-100' : 'opacity-30'}`}>
                <h3
                  role="presentation"
                  onClick={() => {
                    setVisionMission('mission')
                  }}
                  className="text-3xl text-primary cursor-pointer mb-4">{ page.whoWeAre?.mission?.tabTitle }</h3>
                  <div
                    className={`w-full md:w-1/2 ${visionMission === 'mission' ? 'bg-secondary h-1.5' : 'bg-gray-400 h-0.5 mb-1'}`} />
                  <p className="mt-4">{ page.whoWeAre?.mission?.content }</p>
              </div>  
            </div>
          </div>
        </div>
      </section>
      <Values
        values={page.whoWeAre?.values} />
      <section className="pt-12 pb-24">
        <div className="container mx-auto px-8">
          <div className="flex flex-wrap mb-8 -mx-8">
            <div className="w-full px-8">
              <h2
                className="text-6xl text-primary mb-6">
                { page.whoWeAre?.historyTimeline?.title }
              </h2> 
              <h4
                className="text-4xl text-gray-500">
                { page.whoWeAre?.historyTimeline?.subtitle }
              </h4> 
            </div>
          </div>
          <div className="flex flex-wrap -mx-8">
            {page.whoWeAre?.historyTimeline?.timeline?.map(({ year, description }) => (
              <div 
                key={year}
                className="w-full md:w-1/3 px-8 mb-4"> 
                <h5
                  className="text-2xl text-primary pb-3 mb-3 border-b border-gray-200">{ year }</h5>
                <p>{ description }</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/** Resource Section */}
      <Articles />
    </Layout>
  )
}


export const query = graphql`
  query WhoWeAreQuery($databaseId: Int!){
    page: wpPage(databaseId: { eq: $databaseId }){
      title
      content
      featuredImage {
        node {
          localFile {
            publicURL
          }
        }
      }
      whoWeAre{
        headline
        vision {
          tabTitle
          content
        }
        mission {
          tabTitle
          content
        }
        values {
          title
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
              }
            }
          }
          solid {
            value
            content
          }
        }
        historyTimeline {
          title
          subtitle
          timeline {
            year
            description
          }
        }
      }
    }
  }
`




export default WhoWeArePage