import {default as React, useState} from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import DotsIcon from "~/images/dots.inline.svg"

const Values = ({ values: { title = 'Our Values', image: { localFile }, solid = []}}) => {
  const [first = { value: 'Service', content: ''}] = solid
  const [active, setActive] = useState(first)

  return (
    <section className="pb-24">
      <div className="container mx-auto px-8">
        <div className="flex flex-wrap -mx-8">
          <div className="w-full flex flex-col md:w-1/3 px-8 py-16">
            <h3
              className="text-6xl text-primary mb-12">
              { title }
            </h3> 
            <h5
              className="mb-6 mt-auto text-3xl text-primary underline">We are SOLID</h5>
            <ul
              className="flex flex-col list-none m-0 p-0">
              { solid.map((value, i) => ( 
                <li
                  className="mb-4 flex items-center"
                  key={`${value.value}-${i}`}>
                  <a
                    href={`/#`}
                    onClick={e => {
                      e.preventDefault()
                      setActive(value)
                    }}
                    className="text-secondary text-xl font-normal uppercase">
                    {value.value}
                  </a>
                  { active.value === value.value && (
                    <span
                      className="ml-3 inline-block h-2 w-2 bg-secondary rounded-full" />
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div
            style={{
              minHeight: `calc(0.65 * 100vh)`
            }}
            className="flex items-end relative w-full md:w-2/3 px-8 py-16">
            <GatsbyImage
              style={{
                position: 'absolute'
              }}
              className="top-0 left-0 w-full h-full object-cover object-center z-10"
              image={getImage(localFile)} 
              alt={title} />
            <div
              className="relative md:-ml-24 p-12 bg-secondary bg-opacity-95 text-white z-20">
              <h3
                className="text-4xl text-white mb-4">{ active?.value }</h3>
              <p>{ active?.content }</p>
            </div>
            <DotsIcon
               className="w-24 h-24 absolute bottom-0 right-0 -mb-2 -mr-2" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Values